import React from "react"
import Navbar from "./navbar";
import "@fontsource/lato"

export default function Layout({ children, props }) {
    return (
        <main>
            <Navbar />
            <div style={{ margin: `0 auto`, maxWidth: 1000, padding: `0 1rem` }}>
                {children}
            </div>
        </main>
    )
}