import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Logo from "./logo";
import "./navbar.css";

function Navbar() {

    const [setVisable, setVisableState] = useState("translate3d(1000vw, 0, 0)");


    function toggleVisable() {
        setVisableState(setVisable == "translate3d(100vw, 0, 0)" ? "translate3d(0vw, 0, 0)" : "translate3d(100vw, 0, 0)");
    }

    function closeVisable() {
        console.log('lame')
        setVisableState("translate3d(100vw, 0, 0)");
    }




    return (
        <div>
            <div className="menu" onKeyDown={() => { toggleVisable() }} onClick={() => { toggleVisable() }} >
                <svg className="menuSvg" width="1em" height="22" viewBox="0 0 88 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M86.875 0.5H1.125C0.64375 0.5 0.25 0.89375 0.25 1.375V8.375C0.25 8.85625 0.64375 9.25 1.125 9.25H86.875C87.3563 9.25 87.75 8.85625 87.75 8.375V1.375C87.75 0.89375 87.3563 0.5 86.875 0.5ZM86.875 68.75H1.125C0.64375 68.75 0.25 69.1437 0.25 69.625V76.625C0.25 77.1063 0.64375 77.5 1.125 77.5H86.875C87.3563 77.5 87.75 77.1063 87.75 76.625V69.625C87.75 69.1437 87.3563 68.75 86.875 68.75ZM86.875 34.625H1.125C0.64375 34.625 0.25 35.0187 0.25 35.5V42.5C0.25 42.9813 0.64375 43.375 1.125 43.375H86.875C87.3563 43.375 87.75 42.9813 87.75 42.5V35.5C87.75 35.0187 87.3563 34.625 86.875 34.625Z" fill="black" />
                </svg>
            </div>
            <div className="navbar nav-fill w-100 navbar-expand-lg">
                <div class="collapse navbar-collapse" id="navbarText">
                    <div class="nav-item">
                        <Link to="/about">ABOUT</Link>
                    </div>
                    <div class="nav-item">
                        <Link to="/education">EDUCATION</Link>
                    </div>
                </div>
                <div style={{ padding: "0px", zIndex: "5" }} class="nav-item">
                    <Link to="/"><Logo /></Link>
                </div>
                <div class="collapse navbar-collapse" >
                    <div class="nav-item">
                        <Link to="/blog">BLOG</Link>
                    </div>
                    <div class="nav-item">
                        <Link to="/portfolio">PORTFOLIO</Link>
                    </div>
                </div>

            </div>
            <div style={{ transform: `${setVisable}` }} className="hiddenMenu">
                <div>
                    <div onClick={() => { closeVisable() }} onKeyDown={() => { toggleVisable() }} style={{ visibility: 'hidden', padding: "0px" }} class="nav-item">
                        <Link to="/"><Logo /></Link>
                    </div>
                    <div onClick={() => { closeVisable() }} onKeyDown={() => { toggleVisable() }} class="nav-item centerHidden">
                        <Link to="/">HOME</Link>
                    </div>
                    <div onClick={() => { closeVisable() }} onKeyDown={() => { toggleVisable() }} class="nav-item centerHidden">
                        <Link to="/about">ABOUT</Link>
                    </div>
                    <div onClick={() => { closeVisable() }} onKeyDown={() => { toggleVisable() }} class="nav-item centerHidden">
                        <Link to="/education">EDUCATION</Link>
                    </div>
                    <div onClick={() => { closeVisable() }} onKeyDown={() => { toggleVisable() }} class="nav-item centerHidden">
                        <Link to="/blog">BLOG</Link>
                    </div>
                    <div onClick={() => { closeVisable() }} onKeyDown={() => { toggleVisable() }} class="nav-item centerHidden">
                        <Link to="/portfolio">PORTFOLIO</Link>
                    </div>
                </div>
            </div>
        </div >
    );

}

export default Navbar;